import Swal from 'sweetalert2';

const DeleteWidget = {
    mounted() {
        //console.log("DeleteWidget hook mounted");

        this.el.addEventListener('click', (event) => {
            event.preventDefault();  

            const widgetId = this.el.getAttribute('widget_id');
            const actionEvent = this.el.getAttribute('action_event') || 'remove-widget';
            const actionMessage = this.el.getAttribute('action_message') || 'Are you sure you want to delete this widget?';
            const mainTitle = this.el.getAttribute('main_title') || 'Confirm Delete';
            const buttonText = this.el.getAttribute('button_text') || 'Delete';
            const cancelButtonText = this.el.getAttribute('cancel_button_text') || 'Cancel';

            if (!widgetId) {
                console.warn("No widget ID found");
                return;
            }
            //console.log(`DeleteWidget hook clicked for widget ID: ${widgetId}`);
            //console.log(`Action event: ${actionEvent}`);

            Swal.fire({
                title: mainTitle,
                text: actionMessage,
                icon: 'info',
                allowOutsideClick: false,
                showCancelButton: true,
                confirmButtonText: buttonText,
                cancelButtonText: cancelButtonText,
                confirmButtonColor: '#d33',
                cancelButtonColor: '#3085d6',
            }).then((result) => {
                if (result.isConfirmed) {
                    //console.log(`Confirmed delete for widget ID: ${widgetId}`);
                    this.pushEvent(actionEvent, { widget_id: widgetId });
                }
            });
        });
    }
};

export default DeleteWidget;
