function positions(items) {
	return items.reduce((acc, item) => {
		let widgetId = item.el.id;
		acc[widgetId] = {
			x: item.x,
			y: item.y,
			w: item.w,
			h: item.h,
		};
		return acc;
	}, {});
}

function full_positions(items) {
	return items.map(item => {
		
		return {
			id: item.id,
			x: item.x,
			y: item.y,
			w: item.w,
			h: item.h,
		};

	}, {});
}


const GridstackHook = {
	mounted() {
		this.grid = GridStack.init({
			cellHeight: 80,
			columnOpts: { layout: 'list', columnWidth: 100 },
			draggable: {
				cancel: '.no_draggable'
			},
			always_show_resize_handle: true,
			resizable: { autoHide: false, handles: "se" }
		});
		this.el.style.display = 'block';
		if(this.el.children.length > 0){
			this.el.children[0].classList.remove('ui-resizable-autohide');//autoHide does not work in some situations
		}
		document.getElementById('widget-spinner').style.display = 'none';

		this.grid.on('change', (_event, items) => {

		
			var serializedFull = this.grid.save(true, true);
			var serializedData = serializedFull.children;
			
			if (items) {
				this.pushEventTo(this.el, 'widget:move', {
					positions: positions(items),
					all: full_positions(serializedData)
				});
			}
		});
	},

	beforeUpdate() {
		this.grid.destroy(false);
	},

	updated() {
		this.grid = GridStack.init({
			cellHeight: 80,
			columnOpts: { layout: 'list', columnWidth: 100 },
			draggable: {
				cancel: '.no_draggable'
			},
			always_show_resize_handle: true,
			resizable: { autoHide: false, handles: "se" }
		});

		this.el.style.display = 'block';
		if(this.el.children.length > 0){
			this.el.children[0].classList.remove('ui-resizable-autohide');//autoHide does not work in some situations
		}
		document.getElementById('widget-spinner').style.display = 'none';

		this.grid.on('change', (_event, items) => {
			
			var serializedFull = this.grid.save(true, true);
			var serializedData = serializedFull.children;
			

			if (items) {
				this.pushEventTo(this.el, 'widget:move', {
					positions: positions(items),
					all: full_positions(serializedData)
				});
			}
		});
	},

	beforeDestroy() {
		this.grid.destroy(false);
	},
};

export default GridstackHook;
