const screens = {
  sm: 480,
  md: 768,
  lg: 992,
  xl: 1200,
  grxl: 1350,
  xxl: 1400,
  grxxl: 1700,
  xxxl: 1800,
}

const IndexHeightHook = {
  mounted() {

    grid_height = this.el.clientHeight;
    grid_width = this.el.clientWidth;
   

    const windowWidth = window.innerWidth;

    let grid_size = {
      grid_height: grid_height,
      grid_width: grid_width,
      screen_width: windowWidth,
      breakpoints: screens
    }

    this.pushEventTo(this.el, 'grid_size', grid_size);

  }

  
}

export default IndexHeightHook;