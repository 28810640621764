import Tom from 'tom-select';

const  RegisterTypeSelectHook = {
	
	mounted() {
		item = new Tom(this.el, {
			maxOptions: 1000,
			sortField: {
				field: 'text',
				direction: 'asc',
			},
			render: {
				option: function (data, escape) {
					return (
						'<div class="h-8 my-2"><span class="p-1 rounded-md border-2 border-' + escape(data.color) +' bg-' + escape(data.bg_color) +' text-' + escape(data.color) +'">'+
            					escape(data.label) +
          				'</span></div>'
					);
				},
				item: function (data, escape) {
					return (
						'<div class="h-8 my-2"><span class="p-1 rounded-md border-2 border-' + escape(data.color) +' bg-' + escape(data.bg_color) +' text-' + escape(data.color) +'">'+
            					escape(data.label) +
          				'</span></div>'
					);
				},
			},
		});
	},
	updated() {
   }
};

export default RegisterTypeSelectHook;



