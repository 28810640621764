import Swal from 'sweetalert2';


const DeleteConfirm = {
    mounted() {
        //console.log("DeleteConfirm hook mounted");
        this.el.addEventListener('click', (event) => {
            event.preventDefault();
            //console.log("Delete confirm clicked");
            const actionMessage = this.el.getAttribute('action_message') || "Are you sure you want to delete this item?";
            const actionEvent = this.el.getAttribute('action_event');
            const iconType = this.el.getAttribute('icon_type') || "info";
            const mainTitle = this.el.getAttribute('main_title') || "Confirm Delete";
            const buttonText = this.el.getAttribute('button_text') || "Confirm";
            const cancelButtonText = this.el.getAttribute('cancel_button_text') || "Cancel";

            const identifier = Array.from(this.el.attributes).find(attr => attr.name.endsWith('_id'))?.value;

            if (!identifier) {
                console.warn("No identifier found on element", this.el);
                return;
            }

            Swal.fire({
                title: mainTitle,
                text: actionMessage,
                icon: iconType,
				allowOutsideClick: false,
                showCancelButton: true,
                confirmButtonText: buttonText,
                cancelButtonText: cancelButtonText,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
            }).then((result) => {
                if (result.isConfirmed) {
                    this.pushEventTo(this.el, actionEvent, { id: identifier });
                }
            });
        });
    }
};

export default DeleteConfirm;