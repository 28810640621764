const options = {
    slidesPerView: 1,
    spaceBetween: 30,
    keyboard: {
        enabled: true,
    },
    pagination: {
        el: ".swiper-pagination",
        clickable: true,
        type: 'bullets'
    },
    // navigation: {
    //     nextEl: ".swiper-button-next",
    //     prevEl: ".swiper-button-prev",
    // },
    loop: true,
    autoplay: {
        delay: 1500,
        disableOnInteraction: false
    }
}
const LoginSwiperHook = {
    mounted() { 
        new Swiper(".keyboard-control", options);
    },
    updated() {
        new Swiper(".keyboard-control", options);
    }
  }
  
  export default LoginSwiperHook;
