import Tom from 'tom-select';

const  ColorSelectHook = {
	
	mounted() {
		item = new Tom(this.el, {
			maxOptions: 1000,
			sortField: {
				field: 'text',
				direction: 'asc',
			},
			render: {
				option: function (data, escape) {
					return (
						'<div class="flex p-2 items-center" >' +
						'<div class="bg-' +
						data.color +
						' h-4 w-4">&nbsp;</div>' +
						'<div class="pl-2">' +
						data.label +
						'</div>' +
						'</div>'
					);
				},
				item: function (data, escape) {
					return (
						'<div class=""><span class="bg-' +
						escape(data.color) +
						' h-4 w-4 pr-4"></span>&nbsp;<span class="">' +
						escape(data.label) +
						'</span></div>'
					);
				},
			},
		});
	},
	updated() {
   }
};

export default ColorSelectHook;



