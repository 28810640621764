// Test Hook

const  LogHook = {
	
	mounted() {
   },
	updated() {
   }
};

export default LogHook;