import Swal from 'sweetalert2';

const ConfirmAction = {
	mounted() {
	  this.el.addEventListener("click", (event) => {
		event.preventDefault(); 
		
		const actionMessage = this.el.getAttribute("action_message") || "Are you sure?";
		const mainTitle = this.el.getAttribute("main_title") || "Confirm Action";
		const buttonText = this.el.getAttribute("button_text") || "Confirm";
		const iconType = this.el.getAttribute("icon_type") || "info";
		const cancel_button_text = this.el.getAttribute('cancel_button_text') || "Cancel";
  
		const alarmId = this.el.dataset.alarmId;
		const status = this.el.dataset.status;
  
		Swal.fire({
		  title: mainTitle,
		  text: actionMessage,
		  icon: iconType,
		  allowOutsideClick: false,
		  showCancelButton: true,
		  confirmButtonText: buttonText,
		  cancelButtonText: cancel_button_text,
		  confirmButtonColor: '#3085d6',
		  cancelButtonColor: '#d33',
		}).then((result) => {
		  if (result.isConfirmed) {
			this.pushEvent("update-alarm-status", { alarm_id: alarmId, status: status });
		  }
		});
	  });
	},
  };

    export default ConfirmAction;
  