const ResizerHook = {
	mounted() {
		const resizer = document.getElementById('dragPannel');

		const pannelRight = document.getElementById('right-panel-width');

		let x = 0;
		let rightWidth = 0;
		let totalWidth = 0;

		const ref = this;

		const mouseDownHandler = function (e) {
			x = e.clientX;

			rightWidth = pannelRight.getBoundingClientRect().width;
			// total width is full screen width
			totalWidth = window.innerWidth;

			// calculate the current percentage of the right panel
			rightWidthPercentage = (rightWidth / totalWidth) * 100;

			document.addEventListener('mousemove', mouseMoveHandler);
			document.addEventListener('mouseup', mouseUpHandler);
		};

		resizer.addEventListener('mousedown', mouseDownHandler);

		const mouseMoveHandler = function (e) {
			const dx = e.clientX - x;

			let newrightWidth = rightWidth - dx;

			let newLeftPercentage = (newrightWidth / totalWidth) * 100;

			pannelRight.style.width = `${newLeftPercentage}%`;

			document.body.style.cursor = 'col-resize';
			pannelRight.style.userSelect = 'none';
			pannelRight.style.pointerEvents = 'none';
		};

		const mouseUpHandler = function () {
			resizer.style.removeProperty('cursor');
			document.body.style.removeProperty('cursor');
			pannelRight.style.removeProperty('user-select');
			pannelRight.style.removeProperty('pointer-events');
			// rightSide.style.removeProperty('user-select');
			// rightSide.style.removeProperty('pointer-events');
			// rightSide.classList.remove('dragging');

			document.removeEventListener('mousemove', mouseMoveHandler);
			document.removeEventListener('mouseup', mouseUpHandler);

			ref.pushEvent('user_resize', { width: leftSide.style.width });

			const notifyHeightHook = new CustomEvent('notifyHeightHook', {
				detail: {
					message: 'Canvas was resized',
					rightWidth: leftSide.style.width,
				},
			});
			window.dispatchEvent(notifyHeightHook);
		};
	},
	updated() {},
};

export default ResizerHook;
