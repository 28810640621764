const options = {
	multiselect: {
		allowHTML: true,
		placeholderValue: 'Select...',
		searchPlaceholderValue: 'Search',
		removeItemButton: true,
	},
	multitag: {
		allowHTML: true,
		placeholderValue: 'Digit new value...',
		searchPlaceholderValue: 'Search',
		removeItemButton: true,
	},
};

const ChoicesHook = {

	mounted() {

		initial_options = options[this.el.dataset.type]
		id = this.el.dataset.id
		choices = new Choices(this.el, initial_options);

	


		this.handleEvent('update_choices_'+id, (data) => {
		
			
			initial_options.choices = data.choices

			choices.destroy();		
			choices = new Choices(this.el, initial_options );

		});

	},
	updated() {
		

		choices.destroy();
		choices = new Choices(this.el, options[this.el.dataset.type]);
	},
};

// let ChoicesHook = {
// 	mounted() {
// 		console.log('choices mounted');
// 		if (!this.el.dataset.choicesInitialized) {
// 			this.initializeChoices();
// 			this.el.dataset.choicesInitialized = true;
// 		}
// 	},
// 	updated() {
// 		console.log('choices updated');
// 		if (!this.el.dataset.choicesInitialized) {
// 			this.initializeChoices();
// 			this.el.dataset.choicesInitialized = true;
// 		}
// 	},
// 	initializeChoices() {
// 		console.log('choices Initialized');

// 		resp = new Choices(this.el, options[this.el.dataset.type]);
// 		console.log('RESP: ', resp);
// 	},
// };

export default ChoicesHook;
