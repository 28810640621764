let maps = {};
let all_overlays = [];

async function initMultipleMap(mapId, lat, lng) {
	// Request needed libraries.
	const { Map, InfoWindow } = await google.maps.importLibrary('maps');
	const { AdvancedMarkerElement, PinElement } =
		await google.maps.importLibrary('marker');
	const { DrawingManager } = await google.maps.importLibrary('drawing');

	maps[mapId] = new google.maps.Map(document.getElementById(`${mapId}`), {
		center: new google.maps.LatLng(lat, lng),
		mapTypeId: google.maps.MapTypeId.ROADMAP,
		zoom: 5,
		zoomControl: false,
		keyboardShortcuts: false,
		streetViewControl: false,
		mapTypeControl: false,
		fullscreenControl: false,
		draggable: false,
	});
}

async function drawShapes(mapId, shapes) {
	// Request needed libraries.
	const { Map, InfoWindow } = await google.maps.importLibrary('maps');
	const { AdvancedMarkerElement, PinElement } =
		await google.maps.importLibrary('marker');
	const { DrawingManager } = await google.maps.importLibrary('drawing');

	var latlngbounds = new google.maps.LatLngBounds();

	let map = maps[mapId];
	shapes.forEach((shape) => {
		shape.forEach((coord) => {
			let latlng = new google.maps.LatLng(coord[0], coord[1]);
			latlngbounds.extend(latlng);  // Extending the global latlngbounds
		});

		let path = parseShapeCords2(shape);

		let newShape = new google.maps.Polygon({
			paths: path,
			strokeColor: '#000',
			strokeOpacity: 0.8,
			strokeWeight: 2,
			fillColor:
				shape[0].name === shape[0].current_geofence ? '#ffff00' : '#009fff',
			fillOpacity: 0.35,
			editable: false,
      cursor: 'default',
		});

		shape_names = shape[0].name;
		shapes_id = shape[0].id;
		current_geofence = shape[0].current_geofence;
		all_overlays.push(newShape);
		newShape.name = shape_names;
		newShape.current_geofence = current_geofence;
		newShape.id = shapes_id;

		newShape.setMap(map);
	});
  if (shapes.length > 0) {
    map.fitBounds(latlngbounds);
  }
  
}

function parseShapeCords2(shape) {
	return shape.map((cord) => {
		return {
			lat: parseFloat(cord[0]),
			lng: parseFloat(cord[1]),
		};
	});
}

const GeofenceCardHook = {
	mounted() {
		const mapId = this.el.id;

		const handleMapPosition = ({
			mapId: eventMapId,
			lat,
			lng,
			zoom,
			shapes,
		}) => {
			if (mapId !== eventMapId) return;

			if (lat == null || lng == null) {
				lat = 50.4497958670619; 
				lng = 10.538987420153404;
				zoom = 2;
			}

			initMultipleMap(mapId, lat, lng);
			drawShapes(mapId, shapes);
		};
		this.handleEvent('mapPosition', handleMapPosition);
	},
};

export default GeofenceCardHook;