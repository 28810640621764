const ResizerHook = {
	mounted() {
		//console.log('Resizer Hook Mounted....');

		const resizer = document.getElementById('dragMe');
		const leftSide = resizer.previousElementSibling;
		const rightSide = resizer.nextElementSibling;

		let x = 0;
		let leftWidth = 0;
		let totalWidth = 0;

		const ref = this;

		const mouseDownHandler = function (e) {
			x = e.clientX;
			leftWidth = leftSide.getBoundingClientRect().width;
			totalWidth = leftSide.offsetWidth + rightSide.offsetWidth;

			document.addEventListener('mousemove', mouseMoveHandler);
			document.addEventListener('mouseup', mouseUpHandler);
		};

		resizer.addEventListener('mousedown', mouseDownHandler);

		const mouseMoveHandler = function (e) {
			const dx = e.clientX - x;
			let newLeftWidth = leftWidth + dx;
			let newLeftPercentage = (newLeftWidth / totalWidth) * 100;

			if (newLeftPercentage < 10) newLeftPercentage = 10;
			if (newLeftPercentage > 90) newLeftPercentage = 90;

			leftSide.style.width = `${newLeftPercentage}%`;

			document.body.style.cursor = 'col-resize';
			leftSide.style.userSelect = 'none';
			leftSide.style.pointerEvents = 'none';
			rightSide.style.userSelect = 'none';
			rightSide.style.pointerEvents = 'none';
			rightSide.classList.add('dragging');
		};

		const mouseUpHandler = function () {
			resizer.style.removeProperty('cursor');
			document.body.style.removeProperty('cursor');
			leftSide.style.removeProperty('user-select');
			leftSide.style.removeProperty('pointer-events');
			rightSide.style.removeProperty('user-select');
			rightSide.style.removeProperty('pointer-events');
			rightSide.classList.remove('dragging');

			document.removeEventListener('mousemove', mouseMoveHandler);
			document.removeEventListener('mouseup', mouseUpHandler);

			ref.pushEvent('user_resize', {
				width: leftSide.style.width,
			});

			const notifyHeightHook = new CustomEvent('notifyHeightHook', {
				detail: {
					message: 'Canvas was resized',
					leftWidth: leftSide.style.width,
				},
			});
			window.dispatchEvent(notifyHeightHook);
		};
	},
	updated() {},
};

export default ResizerHook;
