const ColumnsDropdownHook = {
  mounted() {
    const dropdownToggle = this.el.querySelector('#dropdownToggle');
    const dropdownMenu = this.el.querySelector('#dropdownMenu');

    dropdownToggle.addEventListener('click', function(event) {
      dropdownMenu.classList.toggle('hidden');
    });

    // Ensure the click event propagates for checkboxes to trigger phx-click
    dropdownMenu.addEventListener('click', function(event) {
      if (event.target.tagName.toLowerCase() !== 'input') {
        event.stopPropagation();
      }
    });

    document.addEventListener('click', function(event) {
      if (!dropdownMenu.contains(event.target) && !dropdownToggle.contains(event.target)) {
        dropdownMenu.classList.add('hidden');
      }
    });
  }
}

export default ColumnsDropdownHook;