import Swal from 'sweetalert2';

const ResetLayout = {
	mounted() {
	  this.el.addEventListener("click", (event) => {
		event.preventDefault();
  
		const actionMessage = this.el.getAttribute("action_message") || "Are you sure you want to reset the layout?";
		const actionEvent = this.el.getAttribute("action_event") || "reset_layout";
		const iconType = this.el.getAttribute("icon_type") || "info";
		const mainTitle = this.el.getAttribute("main_title") || "Confirm Reset";
		const buttonText = this.el.getAttribute("button_text") || "Confirm";
		const cancelButtonText = this.el.getAttribute("cancel_button_text") || "Cancel";
  
		Swal.fire({
		  title: mainTitle,
		  text: actionMessage,
		  icon: iconType,
		  allowOutsideClick: false,
		  showCancelButton: true,
		  confirmButtonText: buttonText,
		  cancelButtonText: cancelButtonText,
		  confirmButtonColor: '#3085d6',
		  cancelButtonColor: '#d33',
		}).then((result) => {
		  if (result.isConfirmed) {
			this.pushEventTo(this.el, actionEvent, {});
		  }
		});
	  });
	}
  };

  export default ResetLayout;