const CsvExportHook = {
  mounted() {
    this.handleEvent("download_csv", ({ content, file_name }) => {
      const blob = new Blob([content], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");

      link.href = URL.createObjectURL(blob);
      link.download = file_name;
      link.click();
    });
  }
};

export default CsvExportHook;