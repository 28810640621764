import Croppie from 'croppie';

const CroppieHook = {
	mounted() {
		let relay = this;
		let live_component_id= this.el.id;
		

		croppyInstance = new Croppie(this.el, 
			JSON.parse(this.el.getAttribute('data-croppie')),
		);


		const cropImage = () => {
			croppyInstance.result('base64').then((base64) => {
				relay.pushEventTo("#"+live_component_id,'crop', base64);
			});
		};
		this.handleEvent('crop', cropImage);
	},
	updated() {},
};

export default CroppieHook;
