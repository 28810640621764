// const BeforeUnloadHook = {
// mounted() {

// 	// need to load text

// 	var current_url = window.location.href;
// 	//reload page listener
// 	//window.addEventListener("beforeunload", beforeUnloadListener, { capture: true });

// 	//window.addEventListener("beforeunload", beforeUnloadListener, { capture: true });
// 	console.log("Before Unload Hook");

// 	window.addEventListener("beforeunload", function(event) {

// 			console.log("BBBBBBBBBBBBBBBBBBBBBBEEEEEEEEEEEEEFFFFFFFFFFFFFFFFFFFOOOOOOOOOOOOOOOOOOOORRRRRRRRRRRRRRRRRRRRRRRREE")
// 			console.log( event );
// 			console.log( event.target.location.href )
			
// 			var changed = document.getElementById('changed').innerHTML;

// 			console.log(changed);

// 			if (changed === "true") {


// 				console.log("Change is true [beforeunload]");
				
// 				// swal({
// 				// 	title: "Are you sure?",
// 				// 	text: "You have unsaved changes. Are you sure you want to leave?",
// 				// 	icon: "warning",
// 				// 	buttons: true,
// 				// 	dangerMode: true,
// 				//   })
// 				//   .then((willDelete) => {
// 				// 	if (willDelete) {

// 				// 		//window.removeEventListener('beforeunload', beforeUnloadListener, { capture: true });
// 				// 		window.location.href = link.href;

				
// 				// 	}
// 				// 	else
// 				// 	{
// 				// 		event.preventDefault();
// 				// 		event.stopImmediatePropagation();
// 				// 	}

// 				//   });
				
// 				// const confirmationMessageLink = 'You have unsaved changes. Are you sure you want to leave?';
// 				// if (confirm(confirmationMessageLink)) {
// 				//     window.removeEventListener('beforeunload', beforeUnloadListener, { capture: true });
// 				//     window.location.href = link.href;
// 				// } else {
// 				//     event.preventDefault();
// 				//     event.stopImmediatePropagation();
// 				// }
			
				
// 				event.preventDefault();
// 				event.stopImmediatePropagation();
				
// 			}

		
// 		}, true);



// 	// //back page listener
// 	// window.addEventListener("popstate", function(event){
// 	// console.log("popstate")
// 	// var changed = document.getElementById('changed').innerHTML;
// 	// if (changed === "true") {
// 	// 	const confirmationMessage = 'You have unsaved changes. Are you sure you want to leave?';
// 	// 	if (!confirm(confirmationMessage)) {
// 	// 		console.log(current_url)
// 	// 		history.pushState(null, '', current_url)
// 	// 		event.preventDefault();
// 	// 		event.stopImmediatePropagation();
// 	// 		return false;
// 	// 	}
// 	// }
// 	// });
//     // document.querySelectorAll('a').forEach((link) => { 
//     //     console.log(link)
//     // });
    
    
// 	//link listener
    
// 	document.addEventListener('click', function(event) {
// 		console.log("Click Unload");
// 		console.log(event);

// 		classlist = event.target.classList;
// 		dataset = event.target.dataset;

// 		console.log ( dataset) 

// 		if (dataset.back_url != undefined) 
// 		{
// 			back_url = dataset.back_url;
// 		}
// 		else
// 		{
// 			back_url = "/";
// 		}

// 		console.log(classlist);

// 		if (classlist.contains('check-unload') == true) {

// 			var changed = document.getElementById('changed').innerHTML;
		
// 			if (changed === "true") {

// 				event.preventDefault();
// 				event.stopImmediatePropagation();

// 				const confirmationMessageLink = 'You have unsaved changes. Are you sure you want to leave?';
// 				if (confirm(confirmationMessageLink)) {
// 					//window.removeEventListener('beforeunload', beforeUnloadListener, { capture: true });
// 					// reset HTML changed
// 					document.getElementById('changed').innerHTML = "";
// 					window.location.href = back_url;
// 				} else {
// 					event.preventDefault();
// 					event.stopImmediatePropagation();
// 				}
// 			}
// 		}
// 		else

//         if (event.target.matches('a')) {
//             var link = event.target;

// 			//console.log("Click Unload");
           
//             //if(link.classList.contains('class_before_unload') || link.getAttribute('data-phx-link') === 'redirect'){
// 			if(link.classList.contains('class_before_unload') == true) {
			
//                 var changed = document.getElementById('changed').innerHTML;

// 				if (changed === "true") {


// 					//console.log("Change is true [click]");
// 					event.preventDefault();
// 					event.stopImmediatePropagation();

// 					// swal({
// 					// 	title: "Are you sure?",
// 					// 	text: "You have unsaved changes. Are you sure you want to leave?",
// 					// 	icon: "warning",
// 					// 	buttons: true,
// 					// 	dangerMode: true,
// 					//   })
// 					//   .then((willDelete) => {
// 					// 	if (willDelete) {

// 					// 		//window.removeEventListener('beforeunload', beforeUnloadListener, { capture: true });
// 					// 		window.location.href = link.href;

					
// 					// 	}
// 					// 	else
// 					// 	{
// 					// 		event.preventDefault();
// 					// 		event.stopImmediatePropagation();
// 					// 	}

// 					//   });
					
// 					const confirmationMessageLink = 'You have unsaved changes. Are you sure you want to leave?';
//                     if (confirm(confirmationMessageLink)) {
//                         //window.removeEventListener('beforeunload', beforeUnloadListener, { capture: true });
//                         window.location.href = link.href;
//                     } else {
//                         event.preventDefault();
//                         event.stopImmediatePropagation();
//                     }
//                 }
//             }
//         }
//     }, true);
// }
// } 
// export default BeforeUnloadHook;
import Swal from 'sweetalert2';

const BeforeUnloadHook = {
    mounted() {
        //console.log("Before Unload Hook");

        const translations = window.translations;

        history.pushState(null, '', window.location.href);

        window.addEventListener("popstate", (event) => {
            changed = document.getElementById('changed');


            if (changed != null) {
                changed = changed.trim();
            }

            if (changed === "true") {
                //console.log("Change is true [popstate]");
                event.preventDefault();
                event.stopImmediatePropagation();

                Swal.fire({
                    title: translations.title,
                    text: translations.text,
                    icon: "info",
                    allowOutsideClick: false,
                    showCancelButton: true,
                    confirmButtonText: translations.confirm,
                    cancelButtonText: translations.cancel,
                    confirmButtonColor: '#d33',
                    cancelButtonColor: '#3085d6',
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.removeEventListener("popstate", this.handlePopState);
                        history.back();
                    } else {
                        history.pushState(null, '', window.location.href);
                    }
                });
            } else {
                history.back();
            }
        });

        document.addEventListener('click', (event) => {
            //console.log("Click Unload");

            const classlist = event.target.classList;
            const dataset = event.target.dataset;
            const back_url = dataset.back_url !== undefined ? dataset.back_url : "/";

            if (classlist.contains('check-unload')) {
                changed = document.getElementById('changed').innerHTML;
                if (changed != null) {
                    changed = changed.trim();
                }
                

                if (changed === "true") {
                    event.preventDefault();
                    event.stopImmediatePropagation();

                    Swal.fire({
                        title: translations.title,
                        text: translations.text,
                        icon: "info",
                        showCancelButton: true,
                        confirmButtonText: translations.confirm,
                        cancelButtonText: translations.cancel,
                        allowOutsideClick: false,
                        confirmButtonColor: '#d33',
                        cancelButtonColor: '#3085d6',
                    }).then((result) => {
                        if (result.isConfirmed) {
                            document.getElementById('changed').innerHTML = "";
                            window.location.href = back_url;
                        }
                    });
                }
            } else if (event.target.matches('a') && event.target.classList.contains('class_before_unload')) {
                const link = event.target;
                changed = document.getElementById('changed').innerHTML;

                if (changed === "true") {
                    event.preventDefault();
                    event.stopImmediatePropagation();

                    Swal.fire({
                        title: translations.title,
                        text: translations.text,
                        icon: "info",
                        allowOutsideClick: false,
                        showCancelButton: true,
                        confirmButtonText: translations.confirm,
                        cancelButtonText: translations.cancel,
                        confirmButtonColor: '#d33',
                        cancelButtonColor: '#3085d6',
                    }).then((result) => {
                        if (result.isConfirmed) {
                            window.location.href = link.href;
                        }
                    });
                }
            }
        }, true);

        // removed beforeunload event listener

        // window.addEventListener("beforeunload", (event) => {
        //     const changed = document.getElementById('changed').innerHTML;

        //     if (changed === "true") {
        //         console.log("Change is true [beforeunload]");
        //         event.preventDefault();
		// 		event.stopImmediatePropagation();
        //     }
        // });
    }
};

export default BeforeUnloadHook;
